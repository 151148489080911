<template>
  <CModal :title="$pgettext('event_wizard.invite.modal','Invite User')" :show="show" @update:show="close" size="lg"
          centered>

    <PFRow :label="$pgettext('event_wizard.invite.modal.label','Users')" size="12x12">
      <div v-for="user in contractManagerUsers" :key="user.id">
        <CInputCheckbox custom
                        :checked="form.userIds[user.user_id]"
                        @click="toggleUserId(user.user_id)"
                        :label="user.email"/>
      </div>
    </PFRow>
    <PFRow :label="$pgettext('event_wizard.invite.modal.label','Role')" size="12x12">
      <PComponentLoader :loading="!roles">
        <PSelectKeyValue
            @input="onChangeRole"
            :options="roles"
            :value.sync="form.role"
            placeholder="Select Role"
        />
      </PComponentLoader>

      <div v-if="role_detail" v-html="role_detail.description"/>
    </PFRow>
    <template #footer>
      <CButton @click="close" color="secondary" v-translate>Close</CButton>
      <CButton class="btn btn-primary btn-sm"
               @click="invite"
               color="primary"
               :disabled="v$.$invalid"
               translate-context="event_wizard.invite.modal" v-translate>
        Invite
      </CButton>
    </template>
  </CModal>
</template>

<script>

import {useVuelidate} from "@vuelidate/core";
import {required, requiredIf} from "@vuelidate/validators";
import {mapActions, mapGetters} from "vuex";
import PFRow from "@/domain/core/components/PFRow.vue";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";
import PComponentLoader from "@/domain/core/components/PComponentLoader.vue";
import Vue from "vue";

export default {
  name: "ContractManagerUsersModal",
  components: {PComponentLoader, PSelectKeyValue, PFRow},
  emits: ['sent'],
  data() {
    return {
      form: {
        userIds: {},
        role: null,
      },
      role_detail: null,
      show: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('eventInvitations', [
      'roles',
      'contractManagerUsers',
    ]),
  },
  setup() {
    return {v$: useVuelidate()}
  },
  validations() {
    return {
      form: {
        role: {required},
        userIds: {requiredIf: requiredIf(() => Object.keys(this.form.userIds).length === 0)},
      },

    }
  },
  methods: {
    ...mapActions('eventInvitations', [
      'fetchRoles',
      'sendMultipleInvitations',
      'fetchContractManagerUsers'
    ]),
    open() {
      this.form.userIds = {}
      this.form.role = null
      this.role_detail = null
      this.show = true
      if (!this.roles) this.fetchRoles()
      this.loading = true
      this.fetchContractManagerUsers()
      this.loading = false
    },
    async invite() {
      await this.sendMultipleInvitations({
        userIds: Object.keys(this.form.userIds),
        role: this.form.role,
      })
      await this.notifySuccess(this.$pgettext('invitations.notification', 'Role assigned'))
      this.$emit('sent')
      this.close()
    },
    close() {
      this.show = false
    },
    onChangeRole(value) {
      this.role_detail = value.original
    },
    toggleUserId(userId) {
      if (this.form.userIds[userId])
        Vue.delete(this.form.userIds, userId)
      else
        Vue.set(this.form.userIds,userId,true)
    }
  }
}
</script>


<style scoped>

</style>