<template>
  <div>
    <FtpCredentials ref="ftp-credentials"/>
    <h1 class="main-header" v-translate>Users</h1>
    <CRow class="mb-3">
      <CCol>
        <label v-translate translate-context="invitations">Users with access to this event</label>
        <CCard class="no-borders">
          <CCardHeader class="px-2">
            <div class="card-header-actions">
              <CButton color="primary" v-translate translate-context="list.actions"
                       @click="openContractManagerUsersModal()" v-if="hasPerm('invitation.assign')">Assign Roles
              </CButton>
              <CButton color="primary" v-translate translate-context="list.actions"
                       @click="openInvitationModal()" v-if="hasPerm('invitation.invite')">Invite user
              </CButton>
            </div>
          </CCardHeader>
          <CCardBody class="px-0">
            <div class="table-responsive custom-table">
              <CDataTable
                  :hover="false"
                  :bordered="true"
                  :striped="true"
                  :items="invitations.results"
                  :fields="fields"
                  :column-filter="{external: true,lazy:true}"
                  :column-filter-value="filters"
                  :sorter="{external: true, resetable:true}"
                  :loading="loading"
                  :sorter-value="sorting"
                  @update:column-filter-value="updateFilters"
                  @update:sorter-value="updateSorting"
                  class="custom-table custom-table--clean custom-table--has-filters"
              >
                <template #no-items-view>
                  <div class="text-center">
                    <h5 v-translate>No items found</h5>
                  </div>
                </template>
                <template #sorting-icon="{ state, classes }">
                  <div :class="classes">
                    <CIcon name="cipCaretUp" size="lg" v-if="state === 'asc' || state === 0"></CIcon>
                    <CIcon name="cipCaretUp" size="lg" v-else></CIcon>
                  </div>
                </template>
                <template #email="{item}">
                  <td v-if="isStatusValid(item.status)">
                    {{ item.email }}
                    <span v-if="hasPerm('invitation.change_password')">
                      <CButton color="secondary" @click="openChangePassword(item)" class="ml-2" v-translate>
                        Change password
                      </CButton>
                    </span>
                  </td>
                </template>
                <template #uploaded-photos="{item}">
                  <td v-if="isStatusValid(item.status) && item.info">
                    <div class="d-flex align-items-center">
                      <div class="mr-3 font-weight-semi-bold" v-if="item.info.num_uploaded_photos > 0">
                        <CLink :to="routeToUploadedPhotos(item.id)">{{ item.info.num_uploaded_photos }}</CLink>
                      </div>
                      <CButton color="success" variant="outline" v-if="item.info.ftp_credentials" @click="openCredentials(item.info.ftp_credentials)">Credentials</CButton>
                    </div>
                  </td>
                  <td v-else-if="item.status == invitationStatus.PENDING || item.status == invitationStatus.EXPIRED">
                    <translate>{{ getStatusLabel(item.status) }}</translate>
                  </td>
                </template>
                <template #actions="{item}">
                  <td>
                    <div class="d-flex justify-content-end mr-2"
                         v-if="item.status == invitationStatus.PENDING || item.status == invitationStatus.ACCEPTED">
                      <CButton
                          color="primary"
                          variant="outline"
                          class="btn-circle btn-circle--sm mr-2"
                          size="sm"
                          v-c-tooltip="{content: $gettext('Resend invite'), appendToBody: true}"
                          @click="onResend(item.id)"
                          v-if="hasPerm('invitation.delete') && item.status == invitationStatus.PENDING">
                        <CIcon name="cipSend"/>
                      </CButton>
                      <CButton
                          color="danger"
                          variant="outline"
                          class="btn-circle btn-circle--sm"
                          size="sm"
                          v-c-tooltip="{content: $gettext('Cancel invite'), appendToBody: true}"
                          @click="onDelete(item.id)"
                          v-if="hasPerm('invitation.invite')">
                        <CIcon name="cipTrashFull"/>
                      </CButton>
                    </div>
                  </td>
                </template>
                <template #under-table>
                  <CPagination
                      v-show="invitations.pagination.num_pages > 1"
                      :activePage="invitations.pagination.page"
                      @update:activePage="updatePage"
                      :pages="invitations.pagination.num_pages"
                  />
                </template>
              </CDataTable>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <InviteUserModal ref="invite-user-modal" @sent="onInvitationSent"/>
    <ContractManagerUsersModal ref="contract-manager-users-modal" @sent="onInvitationSent"/>
    <ChangePasswordModal ref="change-password-modal"/>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {INVITATION_STATUS} from "@/domain/eventInvitations/const";
import InviteUserModal from "@/domain/eventInvitations/components/InviteUserModal.vue";
import FtpCredentials from "@/domain/eventInvitations/components/FtpCredentials.vue";
import ContractManagerUsersModal from "@/domain/eventInvitations/components/ContractManagerUsersModal.vue";
import ChangePasswordModal from "@/domain/eventInvitations/components/ChangePasswordModal.vue";

export default {
  name: 'Invitations',
  components: {
    ChangePasswordModal,
    ContractManagerUsersModal,
    FtpCredentials,
    InviteUserModal
  },
  data() {
    return {
      loading: false,
      fields: [
        {key: 'email', label: 'Email'},
        {key: 'role', label: 'Role'},
        {key: 'uploaded-photos', label: 'Uploaded photos', filter: false},
        {key: 'actions', label: 'Actions', sorter: false, filter: false, _style: 'text-align: right'}
      ],
      pagination: {
        page_size: 20,
        page: 1,
      },
      sorting: {
        column: null,
        asc: false
      },
      filters: {},
      showEditor: false
    }
  },

  async mounted() {
    await this.setPicaServiceId(this.$route.params.picaServiceId)
    await this.fetchConfig({type: 'invitation', id: this.$route.params.picaServiceId})
    await this.fetchList()
  },
  computed: {
    ...mapGetters('eventInvitations', [
      'invitations',
      'pica_service_id'
    ]),
    ...mapGetters('eventWizard', [
      'canChoosePhotographerManager'
    ]),
    invitationStatus() {
      return INVITATION_STATUS
    },
  },
  methods: {
    ...mapActions('eventInvitations', [
      'fetchInvitationList',
      'deleteInvitation',
      'sendInvitation',
      'setPicaServiceId',
      'resendInvitation'
    ]),
    ...mapActions('login', [
      'fetchConfig',
    ]),
    async fetchList() {
      this.loading = true
      await this.fetchInvitationList({
        filters: this.filters,
        pagination: this.pagination,
        sorting: this.sorting
      })
      this.pagination.page = this.invitations.pagination.page
      this.loading = false
    },
    updateFilters(filters) {
      this.filters = filters
      this.pagination.page = 1
      this.fetchList()
    },
    updateSorting(sorting) {
      this.sorting = sorting
      this.fetchList()
    },
    updatePage(page) {
      this.pagination.page = page
      this.fetchList()
    },
    onDelete(id) {
      this.confirm({
        cb_confirm: async () => {
          await this.deleteInvitation(id)
          await this.fetchList()
        }
      })
    },
    routeToUploadedPhotos(photographerId) {
      return {name: 'photoSearch.search', params: {picaServiceId: this.pica_service_id, photographerId: photographerId}}
    },
    isStatusValid(status) {
      return status !== INVITATION_STATUS.CANCELED
    },
    getStatusLabel(status) {
      if (status === INVITATION_STATUS.PENDING) {
        return this.$pgettext('invitations.status', 'Invitation pending')
      } else if (status === INVITATION_STATUS.EXPIRED) {
        return this.$pgettext('invitations.status', 'Invitation expired')
      } else {
        return this.$pgettext('invitations.status', 'User disabled')
      }
    },
    openInvitationModal() {
      this.$refs['invite-user-modal'].open()
    },
    openContractManagerUsersModal() {
      this.$refs['contract-manager-users-modal'].open()
    },
    async onInvitationSent() {
      await this.fetchList()
    },
    async onResend(id) {
      await this.resendInvitation(id)
      await this.notifySuccess(this.$pgettext('invitations.notification', 'Invitation resent'))
    },
    openCredentials(credentials) {
      this.$refs['ftp-credentials'].open(credentials)
    },
    openChangePassword(user) {
      this.$refs['change-password-modal'].open(user)
    }
  }
}
</script>
