<template>
  <CModal :title="$pgettext('event_wizard.invite.modal','Invite User')" :show="show" @update:show="close" size="lg"
          centered>
    {{ v$.form.email.valid }}
    <PFRow :label="$pgettext('event_wizard.invite.modal.label','E-Mail')" size="12x12">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <CInput class="mb-0" v-model="form.email" placeholder="E-Mail" @input="onChangeEmail"/>
        </div>
        <div class="ml-3" style="width: 30px">
          <CSpinner v-show="fetchingUser" color="primary" size="sm"/>
        </div>
      </div>
    </PFRow>
    <PFRow :label="$pgettext('event_wizard.invite.modal.label','Send E-mail')" size="12x12">
      <PAlert :fade="false" color="info" v-translate>
        The user will receive an email with an invitation link, if not checked the e-mail must be of a valid pica user.
      </PAlert>
      <CSwitch class="mx-2 mt-2" color="primary" variant="3d" size="sm" :checked.sync="form.require_accept"/>
    </PFRow>
    <PFRow :label="$pgettext('contract_manager.invite.modal.label','Message')" size="12x12" v-if="form.require_accept">
      <CTextarea v-model="form.message" placeholder="Message"/>
    </PFRow>
    <PFRow :label="$pgettext('contract_manager.invite.modal.label','Password')" size="12x12" v-else-if="!form.user_id">
      <CInput v-model="form.password"/>
      <CButton @click="generatePassword" color="secondary">Regenerate</CButton>
    </PFRow>
    <PFRow :label="$pgettext('event_wizard.invite.modal.label','Role')" size="12x12">
      <PComponentLoader :loading="!roles">
        <PSelectKeyValue
            @input="onChangeRole"
            :options="roles"
            :value.sync="form.role"
            placeholder="Select Role"
        />
      </PComponentLoader>

      <div v-if="role_detail" v-html="role_detail.description"/>
    </PFRow>
    <template #footer>
      <CButton @click="close" color="secondary" v-translate>Close</CButton>
      <PButton :loading="loading"
               @click="invite"
               color="primary"
               :disabled="v$.$invalid"
               translate-context="event_wizard.invite.modal" v-translate>
        Invite
      </PButton>
    </template>
  </CModal>
</template>

<script>

import {useVuelidate} from "@vuelidate/core";
import {required, email, minLength, requiredIf, maxLength} from "@vuelidate/validators";
import {mapActions, mapGetters} from "vuex";
import PButton from "@/components/PButton.vue";
import PFRow from "@/domain/core/components/PFRow.vue";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";
import PComponentLoader from "@/domain/core/components/PComponentLoader.vue";
import PAlert from "@/components/PAlert.vue";
import debounce from "lodash/debounce";

export default {
  name: "InviteUserModal",
  components: {PAlert, PComponentLoader, PSelectKeyValue, PFRow, PButton},
  emits: ['sent'],
  data() {
    return {
      form: {
        email: null,
        role: null,
        message: null,
        password: null,
        require_accept: false,
        user_id: null,
      },
      role_detail: null,
      show: false,
      loading: false,
      fetchingUser: false,
    }
  },
  computed: {
    ...mapGetters('eventInvitations', [
      'roles'
    ]),
  },
  setup() {
    return {v$: useVuelidate()}
  },
  validations() {
    return {
      form: {
        email: {required, email},
        role: {required},
        message: {},
        password: {
          minLength: minLength(5),
          maxLength: maxLength(15),
          requiredIf: requiredIf(() => !this.form.user_id && !this.form.require_accept)
        },
      },

    }
  },
  methods: {
    ...mapActions('eventInvitations', [
      'fetchRoles',
      'sendInvitation',
      'fetchUserIdByEmail'
    ]),
    open() {
      this.form.email = null
      this.form.role = null
      this.form.message = null
      this.form.user_id = null
      this.generatePassword()

      this.role_detail = null
      this.show = true
      if (!this.roles) this.fetchRoles()
    },
    async invite() {
      this.loading = true
      await this.try(async () => {
        await this.sendInvitation({
          email: this.form.email,
          role: this.form.role,
          message: this.form.message,
          require_accept: this.form.require_accept,
          password: this.form.password
        })
        await this.notifySuccess(this.$pgettext('invitations.notification', 'Invitation sent'))
        this.$emit('sent')
        this.close()
      }, {
        'user_already_invited_for_this_role': this.$pgettext('invitations.error', 'User already invited for this role'),
        'user_not_found_for_invitation': this.$pgettext('invitations.error', 'User not found for invitation')
      })
      this.loading = false
    },
    close() {
      this.show = false
    },
    generatePassword() {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
      let password = '';
      for (let i = 0; i < 8; i++) {
        password += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      this.form.password = password;
    },
    onChangeRole(value) {
      this.role_detail = value.original
    },
    onChangeEmail: debounce(async function () {
      this.v$.form.email.$touch();
      if (this.v$.form.email.$pending === false && this.v$.form.email.$invalid === false) {
        this.fetchingUser = true
        this.form.user_id = await this.fetchUserIdByEmail(this.form.email)
        this.fetchingUser = false
      }
    }, 500),
  }
}
</script>


<style scoped>

</style>