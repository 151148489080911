<template>
  <CModal :title="$pgettext('event_wizard.invite.modal','Change User Password')" :show="show" @update:show="close"
          size="lg"
          centered>
    <template v-if="show">
      <PFRow :label="$pgettext('event_wizard.invite.modal.label','User')" size="12x12">
        {{ form.user.email }}
      </PFRow>
      <PFRow :label="$pgettext('event_wizard.invite.modal.label','Password')" size="12x12">
        <CInput v-model="form.password"/>
         <PButton
               @click="generatePassword"
               color="secondary"
               translate-context="event_wizard.invite.modal" v-translate>
        Generate
      </PButton>
      </PFRow>
    </template>
    <template #footer>
      <CButton @click="close" color="secondary" v-translate>Close</CButton>
      <PButton
               @click="onChangePassword"
               color="primary"
               :disabled="v$.$invalid"
               translate-context="event_wizard.invite.modal" v-translate>
        Change Password
      </PButton>
    </template>
  </CModal>
</template>

<script>

import {useVuelidate} from "@vuelidate/core";
import {minLength, maxLength, required} from "@vuelidate/validators";
import {mapActions, mapGetters} from "vuex";
import PButton from "@/components/PButton.vue";
import PFRow from "@/domain/core/components/PFRow.vue";

export default {
  name: "ChangePasswordModal",
  components: {PFRow, PButton},
  emits: ['sent'],
  data() {
    return {
      form: {
        user: null,
        password: null,
      },
      show: false,
    }
  },
  computed: {
    ...mapGetters('eventInvitations', [
      'roles'
    ]),
  },
  setup() {
    return {v$: useVuelidate()}
  },
  validations() {
    return {
      form: {
        password: {
          minLength: minLength(5),
          maxLength: maxLength(15),
          required
        },
      },
    }
  },
  methods: {
    ...mapActions('eventInvitations', [
      'changePassword',
    ]),
    open(user) {
      this.form.user = user
      this.form.password = ''
      this.show = true
    },
    async onChangePassword() {
      await this.changePassword({
        userId: this.form.user.user_id,
        password: this.form.password
      })
      await this.notifySuccess(this.$pgettext('invitations.notification', 'Password Changed'))
      this.close()
    },
    close() {
      this.show = false
    },
    generatePassword() {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
      let password = '';
      for (let i = 0; i < 8; i++) {
        password += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      this.form.password = password;
    },
  }
}
</script>