<template>
  <CModal :title="$pgettext('event_wizard.invite.credentials','User Credentials')" :show="show" @update:show="close"
          size="lg" centered>

    <template v-if="credentials">
      <div class="grid-stripped">
        <PFRow :label="$pgettext('event_wizard.invite.credentials','Global')" size="12x12" v-if="credentials.global">
          <FtpCredential :credentials="credentials.global"/>
        </PFRow>
        <PFRow :label="$pgettext('event_wizard.invite.credentials','Pica Service')" size="12x12"
               v-if="credentials.pica_service">
          <FtpCredential :credentials="credentials.pica_service"/>
        </PFRow>
        <PFRow v-for="gallery in credentials.galleries" :key="gallery.id" :label="gallery.name" size="12x12">
          <FtpCredential :credentials="gallery" v-if="!gallery.passive"/>
          <div v-if="gallery.sub_galleries" style="margin-left: 20px;">
            <PFRow v-for="subGallery in gallery.sub_galleries" :key="subGallery.id" :label="subGallery.name"
                   size="12x12">
              <FtpCredential :credentials="subGallery"/>
            </PFRow>
          </div>
        </PFRow>
      </div>
    </template>
    <template #footer>
      <CButton @click="close" color="secondary" v-translate>Close</CButton>
    </template>
  </CModal>
</template>

<script>
import PFRow from "@/domain/core/components/PFRow.vue";
import FtpCredential from "@/domain/eventInvitations/components/FtpCredential.vue";

export default {
  name: "FtpCredentials",
  components: {FtpCredential, PFRow},
  data() {
    return {
      credentials: null,
      show: false,
    }
  },
  methods: {
    open(credentials) {
      this.credentials = credentials
      this.show = true
    },
    close() {
      this.show = false
    },
  }
}
</script>


<style scoped>

</style>