import { render, staticRenderFns } from "./FtpCredential.vue?vue&type=template&id=6ca72737&scoped=true&"
import script from "./FtpCredential.vue?vue&type=script&lang=js&"
export * from "./FtpCredential.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ca72737",
  null
  
)

export default component.exports